import React from 'react';
import { connect } from 'react-redux';
import PageNotFound from '../components/PageNotFound';

import { mapDispatchToProps } from '../services/MapDispatchToProps';
import { mapStateToProps } from '../services/MapStateToProps';

const NotFoundPage = () => <PageNotFound />;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotFoundPage);
