// eslint-disable-next-line
import React, { Component } from 'react';
import Layout from '../Layout';
import image from '../../assets/images/error-404.png';
import { connect } from 'react-redux';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import styles from './style.module.css';

class PageNotFound extends Component {
	render() {
		return (
			<Layout>
				<div className={styles.container}>
					<div className={styles.leftSide}>
						<img src={image} alt="404 not found" />
					</div>
					<div className={styles.rightSide}>
						<h1>PAGE NOT FOUND</h1>
						<p>We can't find the page you're looking for.</p>
						<p>You can either return to the previouse page or visit our homepage.</p>
					</div>
				</div>
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PageNotFound);
